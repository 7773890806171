<template>
  <div class="lawer">
    <div class="lawer_content">
      <div class="ShaiXuan">
        <div><i class="iconfont icon-back" @click="goback"></i></div>
        <div>
          <span @click="othercase">涉及案件</span>
          <span @click="show = true">筛选</span>
        </div>
      </div>
      <div class="company">
        <div>
          <span>{{ this.$route.query.name }}</span>
        </div>
        <div>
          <!-- <i class='iconfont icon-liebiao1'></i> -->
        </div>
      </div>
      <loading :showflag="flag"></loading>
      <ul
        class="case"
        v-for="(item, index) in mainArray"
        :key="index"
        :style="{
          display: item.data[0].graph.datas.length > 0 ? 'block' : 'none'
        }"
      >
        <span v-if="item.data[0].graph.datas.length > 0">
          <li>
            <p>{{ item.title }}</p>
          </li>
          <li>
            <component
              :is="item.type"
              :settings="item.chartSettings"
              :series="item.chartSettings.series"
              :legend="item.chartSettings.legend"
              :colors="['#4B9EFB', '#2DC1E7', '#C0DCFB']"
              :tooltip="item.chartSettings.tooltip"
              :tooltip-visible="true"
              :grid="item.chartSettings.grid"
              :xAxis="item.chartSettings.xAxis"
              :yAxis="item.chartSettings.yAxis"
              :data="item.chartSettings.data"
              :legend-visible="item.chartSettings.legend_visible"
            ></component>
          </li>
          <li
            class="Court"
            v-for="(items, num) in typeof item.showflag == 'boolean'
              ? item.showflag
                ? item.data[1].data
                : item.data[1].data.slice(0, 3)
              : []"
            :key="num"
          >
            <div>
              <span>{{ items.num }}</span>
              <span @click="caselaw(items.key)">{{ items.name }}</span>
            </div>
          </li>
          <div
            class="more"
            style="position:relative;z-index:12;"
            @click="changeflag(index)"
            v-if="
              item.data[1].data.length > 3 && typeof item.showflag == 'boolean'
            "
          >
            {{ item.showflag ? '收起' : '查看全部' }}
          </div>
        </span>
      </ul>
    </div>
    <filter-case
      v-model="show"
      :conditions="cont_contaion"
      @submit="submit"
    ></filter-case>
  </div>
</template>
<script>
import VeRing from 'v-charts/lib/ring.common'
import VeLine from 'v-charts/lib/line.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import { pydataBase } from '~api-config'
import FilterCase from './infoCompoments/filtercase'
import loading from '@/pages/Search/compoments/loading'
import { setTimeout } from 'timers'
export default {
  components: {
    VeRing: VeRing,
    VeHistogram: VeHistogram,
    VeLine: VeLine,
    FilterCase: FilterCase,
    loading: loading
  },
  data() {
    return {
      show: false,
      params_charts: {},
      mainArray: [],
      flag: 1,
      cont_contaion: []
    }
  },
  methods: {
    submit(key) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      /* ?name=${this.$route.query.name}&condition=${key} */
      this.$axios
        .post(`${pydataBase}/api/party/card/his`, {
          name: this.$route.query.name,
          condition: key
        })
        .then(res => {
          totast.clear()
          if (res.data.result.length === 0) {
            this.$notify('暂无判例列表')
          } else {
            this.$router.push({
              path: '/currson/caselaw',
              query: {
                name: this.$route.query.name,
                condition: key
              }
            })
          }
        })
    },
    changeflag(index) {
      this.$set(
        this.mainArray[index],
        'showflag',
        !this.mainArray[index].showflag
      )
    },
    screen() {},
    othercase() {
      this.$router.push({
        path: '/currson/othercase',
        query: {
          name: this.$route.query.name
        }
      })
    },
    // comput () {
    //     this.chartSettings
    // }
    goback() {
      this.$router.go(-1)
    },
    caselaw(flex) {
      this.$store.state.caseDetail.lawer.currson =
        document.documentElement.scrollTop
      this.$router.push({
        path: '/currson/caselaw',
        query: {
          name: this.$route.query.name,
          condition: flex
        }
      })
    }
  },
  created() {
    document.documentElement.scrollTop = 0
  },
  mounted() {
    document.documentElement.scrollTop = 0
    /* ?name=${this.$route.query.name} */
    this.$axios
      .post(`${pydataBase}/api/party/card`, {
        name: this.$route.query.name
      })
      .then(res => {
        this.cont_contaion = res.data.result.conditions
        this.flag = 2
        const mainarr = res.data.result.data
        document.documentElement.scrollTop = 0
        setTimeout(() => {
          if (this.$store.state.caseDetail.lawer.currson) {
            console.log(this.$store.state.caseDetail.lawer.currson)
            document.documentElement.scrollTop = this.$store.state.caseDetail.lawer.currson
            console.log(document.documentElement.style.height)
            this.$store.state.caseDetail.lawer.currson = null
          }
        }, 1000)
        mainarr.forEach((item, index, array) => {
          var tempData = index === 8 ? VeLine : VeRing
          array[index].type =
            index === 3 || index === 4 ? VeHistogram : tempData
          let sum = 0
          let num = 0
          if (array[index].type === VeRing) {
            // 当数组顺序 除  3，4，7 的环图
            this.params_charts = {
              legend: {
                orient: 'vertical',
                x: '32%',
                y: '25%',
                icon: 'circle',
                textStyle: {
                  color: '#333333',
                  fontSize: 14,
                  fontWeight: 500
                },
                formatter(val) {
                  return val
                }
              },
              series: {
                type: 'pie',
                radius: ['48%', '60%'],
                center: ['50%', '30%'],
                data: [],
                label: {
                  position: 'center',
                  show: false,
                  fontSize: 16,
                  color: '#333333',
                  formatter: function(val, index) {
                    num += val.data.value
                    if (val.dataIndex === 1) {
                      return '总计' + num
                    } else {
                      return ''
                    }
                  }
                }
              },
              labelLine: {
                smooth: true,
                length: 10,
                length2: 7
              }
            }
            // 相加之和
            item.data[0].graph.datas.forEach(function(item) {
              sum += item
            })
            // v-charts图表数据 +  name（ 名称 +百分比）
            item.data[0].graph.datas.slice(0, 3).forEach((items, num) => {
              this.params_charts.series.data.push({
                value: items,
                name:
                  item.data[0].graph.name[num] +
                  parseInt((items / sum) * 100) +
                  '%'
              })
            })
            if (item.data[0].graph.datas.length === 1) {
              this.params_charts.legend.x = '35%'
            } else if (item.data[0].graph.datas.length === 2) {
              this.params_charts.legend.x = '30%'
            } else if (item.data[0].graph.datas.length === 3) {
              this.params_charts.legend.y = '20%'
            } else {
              this.params_charts.legend.y = '20%'
            }

            // 返还params_charts
            array[index].chartSettings = this.params_charts
          } else if (array[index].type === VeHistogram) {
            // 柱状图
            this.params_charts = {
              color: ['#4B9EFB'],
              grid: {
                left: '3%',
                right: '0%',
                top: '20',
                containLabel: true,
                height: 200,
                width: '90%'
              },
              xAxis: [
                {
                  type: 'category',
                  data: [],
                  axisTick: {
                    alignWithLabel: true
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name: '直接访问',
                  type: 'bar',
                  data: [],
                  barWidth: 20,
                  itemStyle: {
                    barBorderRadius: [70, 70, 70, 70, 70]
                  },
                  label: {
                    normal: {
                      show: true,
                      position: 'top',
                      textStyle: {
                        color: '#4B9EFB',
                        fontSize: 14,
                        backgroundColor: 'white'
                      },
                      formatter(val) {
                        return ((val.value / sum) * 100).toFixed(2) + '%'
                      }
                    }
                  }
                }
              ]
            }

            item.data[0].graph.name.slice(0, 3).forEach((items, num) => {
              sum += item.data[0].graph.datas[num]
              this.params_charts.xAxis[0].data.push(items)
              this.params_charts.series[0].data.push(
                item.data[0].graph.datas[num]
              )
            })
            array[index].chartSettings = this.params_charts
          } else if (array[index].type === VeLine) {
            // 折线图
            this.params_charts = {
              // tooltip: {
              //   formatter: '{c}'
              // },
              data: {
                columns: ['年份', '件数'],
                rows: []
              },
              grid: {
                left: '3%',
                right: '0%',
                top: '20',
                containLabel: true,
                height: 200,
                width: '90%'
              },
              legend_visible: false
            }
            item.data[0].graph.datas.slice(0, 3).forEach((items, num) => {
              this.params_charts.data.rows.push({
                年份: item.data[0].graph.name[num].toString(),
                件数: Number(items)
              })
            })
            array[index].chartSettings = this.params_charts
          }
        })
        this.mainArray = mainarr
        this.$set(this.mainArray[0], 'showflag', false)
        this.$set(this.mainArray[1], 'showflag', false)
        this.$set(this.mainArray[2], 'showflag', false)
        this.$set(this.mainArray[3], 'showflag', false)
        this.$set(this.mainArray[4], 'showflag', false)
        this.$set(this.mainArray[5], 'showflag', false)
        this.$set(this.mainArray[6], 'showflag', false)
        this.$set(this.mainArray[7], 'showflag', false)
        this.$set(this.mainArray[8], 'showflag', false)
        this.flag = 2
      })
      .catch(err => {
        this.flag = 2
        Promise.reject(err)
      })
  }
}
</script>

<style lang="stylus">
.lawer
  width 100%
  height 100%
  .lawer_content
    width 92%
    margin 0px auto
    padding-bottom 100px
    .company
      display flex
      justify-content space-between
      margin-top 15px
      font-size 15px
      color #000000
      margin-bottom 10px
      box-sizing border-box
    .ShaiXuan
      width 100%
      height 45px
      display flex
      justify-content space-between
      line-height 45px
      margin-top 10px
      border-bottom 1px solid #F3F4F7
      div:nth-child(2)
        span:nth-child(1)
          color #4B9EFB
          font-size 15px
          line-height 20px
          margin-right 15px
        span:nth-child(2)
          color #4B9EFB
          font-size 15px
    .case
      width 100%
      margin-top 20px
      box-shadow 0px 0px 8px 1px rgba(204, 204, 204, 0.5)
      border-radius 5px
      padding-bottom 15px
      border 1px solid transparent
      li:nth-child(1)
        margin-top 15px
        width 90%
        margin 10px auto 0px
        height 25px
        line-height 25px
        display flex
        justify-content space-between
        font-size 15px
        p:nth-child(1)
          color #333333
        p:nth-child(2)
          color #4B9EFB
      li:nth-child(2)
        width 100%
        height 250px
      .Court
        font-size 15px
        width 90%
        position relative
        z-index 10
        margin 0px auto
        div
          margin-top 10px
          display flex
          justify-content space-between
          span:nth-child(1)
            color #4B9EFB
            margin-left 20px
            display block
            width 20%
          span:nth-child(2)
            color #333333
            display block
            width 80%
      .more
        width 100%
        text-align center
        color #666666
        font-size 15px
        margin-top 5px
      .loadingmore
        width 100%
        height 200px
        border 1px solid red
      .TrialJudge
        width 92%
        margin-top 20px
        font-size 15px
        color #333333
        margin 15px auto 0px
        .ZhuZhuang
          width 100%
          height 250px
          margin-top 10px
</style>
