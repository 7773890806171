var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lawer" },
    [
      _c(
        "div",
        { staticClass: "lawer_content" },
        [
          _c("div", { staticClass: "ShaiXuan" }, [
            _c("div", [
              _c("i", {
                staticClass: "iconfont icon-back",
                on: { click: _vm.goback },
              }),
            ]),
            _c("div", [
              _c("span", { on: { click: _vm.othercase } }, [
                _vm._v("涉及案件"),
              ]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = true
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "company" }, [
            _c("div", [_c("span", [_vm._v(_vm._s(this.$route.query.name))])]),
            _c("div"),
          ]),
          _c("loading", { attrs: { showflag: _vm.flag } }),
          _vm._l(_vm.mainArray, function (item, index) {
            return _c(
              "ul",
              {
                key: index,
                staticClass: "case",
                style: {
                  display:
                    item.data[0].graph.datas.length > 0 ? "block" : "none",
                },
              },
              [
                item.data[0].graph.datas.length > 0
                  ? _c(
                      "span",
                      [
                        _c("li", [_c("p", [_vm._v(_vm._s(item.title))])]),
                        _c(
                          "li",
                          [
                            _c(item.type, {
                              tag: "component",
                              attrs: {
                                settings: item.chartSettings,
                                series: item.chartSettings.series,
                                legend: item.chartSettings.legend,
                                colors: ["#4B9EFB", "#2DC1E7", "#C0DCFB"],
                                tooltip: item.chartSettings.tooltip,
                                "tooltip-visible": true,
                                grid: item.chartSettings.grid,
                                xAxis: item.chartSettings.xAxis,
                                yAxis: item.chartSettings.yAxis,
                                data: item.chartSettings.data,
                                "legend-visible":
                                  item.chartSettings.legend_visible,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(
                          typeof item.showflag == "boolean"
                            ? item.showflag
                              ? item.data[1].data
                              : item.data[1].data.slice(0, 3)
                            : [],
                          function (items, num) {
                            return _c(
                              "li",
                              { key: num, staticClass: "Court" },
                              [
                                _c("div", [
                                  _c("span", [_vm._v(_vm._s(items.num))]),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.caselaw(items.key)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(items.name))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        item.data[1].data.length > 3 &&
                        typeof item.showflag == "boolean"
                          ? _c(
                              "div",
                              {
                                staticClass: "more",
                                staticStyle: {
                                  position: "relative",
                                  "z-index": "12",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeflag(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.showflag ? "收起" : "查看全部"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _c("filter-case", {
        attrs: { conditions: _vm.cont_contaion },
        on: { submit: _vm.submit },
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }